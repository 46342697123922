import Lenis from 'lenis';





const lenis = new Lenis({
  duration: 2, // Increase duration to slow down scrolling
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // Custom easing function
  smooth: true,
});

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}
raf()










window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
