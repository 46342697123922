import './Aboutthefounder.scss';
import founder from '../../assets/jpg/founder.jpg'
import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { textFadeIn } from '../../animation/gsapAnimations';

gsap.registerPlugin(ScrollTrigger);

export default function AboutTheFounder() {
    const sectionRef = useRef(null);
    const imgContainerRef = useRef(null);
    const infoContainerRef = useRef(null);
    const textElementsRef = useRef([]);

    useEffect(() => {
        const section = sectionRef.current;
        const imgContainer = imgContainerRef.current;
        const infoContainer = infoContainerRef.current;
        const textElements = textElementsRef.current;

        gsap.fromTo(imgContainer,
            { x: '-100%', opacity: 0 },
            {
                x: 0,
                opacity: 1,
                duration: 1,
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: section,
                    start: 'top bottom',
                    end: 'center center',
                    scrub: true
                }
            }
        );

        gsap.fromTo(infoContainer,
            { x: '100%', opacity: 0 },
            {
                x: 0,
                opacity: 1,
                duration: 1,
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: section,
                    start: 'top bottom',
                    end: 'center center',
                    scrub: true
                }
            }
        );

        // Apply textFadeIn animation to text elements
        textElements.forEach((element, index) => {
            textFadeIn(element, index * 0.2);
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    return (
        <section ref={sectionRef} id='aboutFounder'>
            <div className="container maincontainer">
                <div className='imggcontainer' ref={imgContainerRef}>
                    <img src={founder} alt='founder' />
                </div>

                <div className='founderinfocontainer' ref={infoContainerRef}>
                    <div>
                        <h6 ref={el => textElementsRef.current[0] = el}>About the Founder</h6>
                        <h2 ref={el => textElementsRef.current[1] = el}><span>Meet Mohini Potdar:</span> Art Creator, Curator,
                            and Founder of Artists Strokes</h2>
                    </div>

                    <div>
                        <p ref={el => textElementsRef.current[2] = el}>
                            <strong>Mohini Potdar</strong> is an Art creator and Curator, and the <span> Founder of Artists Strokes</span>.<br />
                            Our company specializes in creating exclusive art forms, including sculptures, paintings, and various other handcrafted works. We connect skilled artisans from all over India, ensuring that every piece is made by hand with care and dedication.
                        </p>

                        <p ref={el => textElementsRef.current[3] = el}>
                            At Artists Strokes, we offer a wide range of inspirational art pieces to enhance your walls. Browse through our collection of wall art to discover the perfect pieces for your home. Whether you're looking for artwork for your living room, kitchen, bedroom, or any other space, we have what you need to transform your walls into tastefully decorated areas.
                        </p>

                        <p ref={el => textElementsRef.current[4] = el}>
                            A house is made of walls, but it becomes a home when those walls reflect the family's creativity. Wall decor is an essential element in the interior design of your dream home. With our diverse selection of wall art, you can turn your home into a sophisticated and captivating environment, moving beyond the ordinary to create something truly unique.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}