import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from "../../components/shared/Navbar/NavBar";
import Header from "../../components/shared/Header/Header";
import HowWeWork from "../../components/HowweWork/HowWeWork";
import BestSellingCategories from "../../components/BestSelling/BestSellingCategories";
// import BestSellingPaintingCategories from "../../components/Bestsellingpaintingcategories/BestSellingPaintingCategories";
import BrIefINformation from "../../components/Brief-Information/BrIefINformation";
import OurService from "../../components/OurService/OurService";
import AboutTheFounder from "../../components/AboutTheFounder/Aboutthefounder";
import SubmitYourArtWork from "../../components/SubmitArtWork/SubmitArtWork";
import FAQ from "../../components/FAQ/FAQ";
import Testimonials from "../../components/Testimonials/Testimonials";
import Footer from "../../components/shared/Footer/Footer";
import Blog from '../../components/blogs/blogs';

export default function Home() {
    const submitArtWorkRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/submit-artwork') {
            submitArtWorkRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    return (
        <div id="home">
            <NavBar />
            <Header />
            <BestSellingCategories />
            <HowWeWork />
            {/* <BestSellingPaintingCategories /> */}
            <BrIefINformation />
            <OurService />
            <AboutTheFounder />
            {/* Attach the ref here */}
            <div ref={submitArtWorkRef}>
                <SubmitYourArtWork />
            </div>
            <FAQ />
            <Blog/>
            <Testimonials />
            <Footer />
        </div>
    );
}
