import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

// Counter component
const Counter = ({ endValue }) => {
  const [count, setCount] = useState(0);
  const counterRef = useRef(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: counterRef.current,
      start: "top 80%",
      onEnter: () => {
        gsap.to({ value: 0 }, {
          value: endValue,
          duration: 2,
          ease: "power1.inOut",
          onUpdate: function() {
            setCount(Math.round(this.targets()[0].value));
          }
        });
      },
      once: true
    });
  }, [endValue]);

  return <span ref={counterRef} className="counterAnim">{count}</span>;
};

// Counter animation component
export const AnimatedCounter = ({ value }) => {
  return <Counter endValue={value} />;
};

// Usage example:
// <AnimatedCounter value={100} />

// No need for DOM-related event listeners in React








// Text fade-in animation
export const textFadeIn = (element, delay = 0, duration = 1) => {
  gsap.set(element, { opacity: 0, y: 50 }); // Set initial state
  
  ScrollTrigger.create({
    trigger: element,
    start: "top 80%",
    onEnter: () => {
      gsap.to(element, {
        opacity: 1,
        y: 0,
        duration: duration,
        delay: delay,
        ease: "power3.out",
      });
    },
    once: true // Ensures the animation only happens once
  });
};

// Usage example:
// In a React component:
// 
// import React, { useEffect, useRef } from 'react';
// import { textFadeIn } from './gsapAnimations';
// 
// const MyComponent = () => {
//   const textRef1 = useRef(null);
//   const textRef2 = useRef(null);
//   const textRef3 = useRef(null);
// 
//   useEffect(() => {
//     // Apply fade-in animation to each text element
//     textFadeIn(textRef1.current);
//     textFadeIn(textRef2.current, 0.2); // 0.2 seconds delay
//     textFadeIn(textRef3.current, 0.4); // 0.4 seconds delay
//   }, []);
// 
//   return (
//     <div>
//       <p ref={textRef1} className="fade-in-text">First text</p>
//       <p ref={textRef2} className="fade-in-text">Second text</p>
//       <p ref={textRef3} className="fade-in-text">Third text</p>
//     </div>
//   );
// };
// 
// export default MyComponent;



export const headingAnimation = (element, delay = 0, duration = 1) => {
  gsap.set(element, { opacity: 0, y: 20 }); // Set initial state
  
  ScrollTrigger.create({
    trigger: element,
    start: "top 80%",
    onEnter: () => {
      gsap.to(element, {
        opacity: 1,
        y: 0,
        duration: duration,
        delay: delay,
        ease: "power3.out",
      });
    },
  
  });
};

export const hrlineAnimation = (element, delay = 0, duration = 1) => {
  ScrollTrigger.create({
    trigger: element,
    start: "top 80%",
    onEnter: () => {
      gsap.to(element, {
        opacity: 1,
        scale: 1,
        
        duration: duration,
        delay: delay,
        ease: "power3.out",
      });
    },
  });
};



export const helpContainerAnimation = (element, delay = 0, duration = .5) => {
  gsap.set(element, { opacity: 0}); // Set initial state
  
  gsap.to(element, {
    opacity: 1,
    duration: duration,
    delay: delay,
    ease: "slow.out",
   
  });
};

