import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './router/main_router';
import './animation/smooth-scoll';
import './animation/gsapAnimations';
import './sass/animation.scss';
import './sass/responsive.scss'

export default function App() {
  return (
    ReactDOM.render(
      <React.StrictMode>
        <AppRouter />
      </React.StrictMode>,
      document.getElementById('root')
    )
  )
}