import React, { useState, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import './BrIefINformation.scss';

const AccordionItem = ({ title, content, isOpen, onToggle }) => {
    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef.current) {
            if (isOpen) {
                gsap.to(contentRef.current, {
                    height: 'auto',
                    opacity: 1,
                    duration: 0.3,
                    ease: 'power2.out'
                });
            } else {
                gsap.to(contentRef.current, {
                    height: 0,
                    opacity: 0,
                    duration: 0.3,
                    ease: 'power2.in'
                });
            }
        }
    }, [isOpen]);

    return (
      <div className="accordion-item">
        <div className="accordion-header" onClick={onToggle}>
          <h6>{title}</h6>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
        <div className="accordion-content" ref={contentRef} style={{height: 0, opacity: 0}}>
          <p>{content}</p>
        </div>
      </div>
    );
  };
  
  const Accordion = ({data}) => {
    const [openIdx, setOpenIdx] = useState(null);
  
    const handleToggle = (idx) => {
      if (openIdx === idx) {
        setOpenIdx(null);  
      } else {
        setOpenIdx(idx);  
      }
    };
  
    return (
      <div className="accordion">
        {data.map((item, idx) => (
          <AccordionItem
            key={idx}
            title={item.title}
            content={item.content}
            isOpen={openIdx === idx}
            onToggle={() => handleToggle(idx)}
          />
        ))}
      </div>
    );
  };
  
  export default Accordion;