// BlogComponent.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const blogData = [

    {
        img: 'https://www.artiststrokes.in/images/homedescore.jpg',
        title: 'Indian Home Decor Ideas: Infusing Tradition with Modern Aesthetics',
        desc: 'Discover how to beautifully combine rich traditions with modern styles to create a warm and inviting home space...',
        content: `
        <h2>Indian Home Decor Ideas: Infusing Tradition with Modern Aesthetics</h2>
        <p>Indian home decor beautifully combines rich traditions with contemporary styles, creating spaces that reflect warmth, culture, and individuality. Whether you’re redesigning a single room or your entire home, here are some inspiring ideas to help you create a stunning Indian-inspired environment.</p>
  
        <h3>1. Embrace Vibrant Colors</h3>
        <p><strong>Indian decor</strong> is known for its bold and vibrant colors. Consider using shades like deep reds, royal blues, and bright yellows. You can add these colors through:</p>
        <ul>
          <li><strong>Accent Walls:</strong> Paint one wall in a rich hue to create a focal point.</li>
          <li><strong>Cushions and Throws:</strong> Use colorful textiles to bring life to neutral furniture.</li>
        </ul>
  
        <h3>2. Incorporate Traditional Textiles</h3>
        <p>Textiles play a crucial role in Indian decor. Look for handwoven fabrics like:</p>
        <ul>
          <li><strong>Silk and Cotton:</strong> Use them for curtains, cushion covers, and table runners.</li>
          <li><strong>Block Prints and Bandhani:</strong> These patterns can be incorporated in upholstery and wall hangings.</li>
        </ul>
  
        <h3>3. Showcase Art and Crafts</h3>
        <p>Indian craftsmanship is world-renowned. Integrate local art into your decor:</p>
        <ul>
          <li><strong>Wall Art:</strong> Hang traditional paintings such as Madhubani or Warli.</li>
          <li><strong>Handcrafted Items:</strong> Display pottery, brassware, or wooden carvings as statement pieces.</li>
        </ul>
  
        <h3>4. Use Antique and Vintage Furniture</h3>
        <p>Mixing modern furnishings with antique pieces can add character to your home. Consider:</p>
        <ul>
          <li><strong>Low Seating:</strong> Add a traditional wooden or wrought-iron chaarpai (charpoy) or ottoman.</li>
          <li><strong>Carved Cabinets:</strong> A vintage almirah or cabinet can serve as both storage and decor.</li>
        </ul>
  
        <h3>5. Create Cozy Nooks with Rugs</h3>
        <p>Rugs can define spaces and add warmth. Look for:</p>
        <ul>
          <li><strong>Persian or Dhurrie Rugs:</strong> These can create a cozy seating area or enhance a living space.</li>
          <li><strong>Jute Rugs:</strong> Ideal for a natural, earthy feel, they complement a variety of decor styles.</li>
        </ul>
  
        <h3>6. Integrate Indoor Plants</h3>
        <p>Plants not only purify the air but also bring life into your home. Choose:</p>
        <ul>
          <li><strong>Potted Plants:</strong> Such as peace lilies, ferns, or snake plants for easy care.</li>
          <li><strong>Hanging Plants:</strong> Macramé hangers can add a bohemian touch.</li>
        </ul>
  
        <h3>7. Use Ethnic Patterns in Wallpaper</h3>
        <p>Wallpaper with ethnic motifs can transform a room. Look for:</p>
        <ul>
          <li><strong>Geometric Designs:</strong> Inspired by traditional Indian patterns.</li>
          <li><strong>Nature-Inspired Prints:</strong> Floral and fauna patterns add a fresh feel.</li>
        </ul>
  
        <h3>8. Incorporate Spiritual Elements</h3>
        <p>Many Indian homes feature spiritual decor. Consider:</p>
        <ul>
          <li><strong>Pooja Spaces:</strong> Create a dedicated area with an altar, traditional artifacts, and soft lighting.</li>
          <li><strong>Statues and Idols:</strong> Include brass or clay figurines of deities as focal points.</li>
        </ul>
  
        <h3>9. Lighting with Character</h3>
        <p>Lighting can dramatically change the ambiance. Opt for:</p>
        <ul>
          <li><strong>Lamps with Traditional Touches:</strong> Use brass or terracotta lamps.</li>
          <li><strong>String Lights:</strong> For a festive feel, especially during celebrations.</li>
        </ul>
  
        <h3>10. Personal Touches</h3>
        <p>Finally, personalize your decor with items that tell your story. Family photographs, travel souvenirs, or handmade crafts can add warmth and individuality to your space.</p>
  
        <h3>Conclusion</h3>
        <p>Creating an Indian-inspired home decor is about blending tradition with modernity, making your space a true reflection of who you are. By incorporating vibrant colors, traditional textiles, and personal touches, you can create a warm and inviting environment that feels uniquely yours. Start small, and gradually add elements that resonate with you, making your home a beautiful tapestry of culture and style.</p>
        <p>- Mohini Potdar (artist)</p>
      `,
    },
    {
        img: 'https://www.artiststrokes.in/images/zaroka.jpg',
        title: 'Transform Your Space: Wooden Jharokha Wall Decor for an Aesthetic Home',
        desc: 'Explore the charm and elegance of wooden jharokha wall decor and how to integrate it into your home for an aesthetic look...',
        content: `
        <h2>Transform Your Space: Wooden Jharokha Wall Decor for an Aesthetic Home</h2>
        <p>When it comes to home decor, few elements evoke the charm and elegance of traditional Indian design quite like a wooden jharokha. These intricately carved balconies or window frames not only add a touch of history to your space but also create a stunning focal point. Here’s how to incorporate wooden jharokha wall decor into your home for an aesthetic look.</p>
  
        <h3>1. Understanding the Jharokha</h3>
        <p>Traditionally, jharokhas were used in palaces and havelis to provide shade and create a sense of privacy while allowing for ventilation and natural light. Today, they serve as beautiful decorative elements, adding depth and character to any room.</p>
  
        <h3>2. Choosing the Right Jharokha</h3>
        <p>When selecting a jharokha for your decor, consider the following:</p>
        <ul>
          <li><strong>Size and Scale:</strong> Choose a size that complements your wall space. A larger jharokha can serve as a stunning focal point, while smaller ones can be arranged in a gallery style.</li>
          <li><strong>Design:</strong> Look for intricate carvings that reflect traditional craftsmanship. Styles can range from floral motifs to geometric patterns.</li>
          <li><strong>Material:</strong> Opt for solid wood for durability. Teak, mango, or sheesham wood are popular choices due to their rich hues and longevity.</li>
        </ul>
  
        <h3>3. Placement Ideas</h3>
        <p>Here are some creative ways to incorporate a jharokha into your home:</p>
        <h4>Living Room</h4>
        <ul>
          <li><strong>Focal Point:</strong> Mount a jharokha above a sofa or mantelpiece. Enhance the look with warm lighting to highlight its details.</li>
          <li><strong>Art Display:</strong> Use the jharokha as a frame for artwork or photographs. This creates an artistic display that draws the eye.</li>
        </ul>
  
        <h4>Bedroom</h4>
        <ul>
          <li><strong>Headboard Alternative:</strong> Position a jharokha behind your bed for a unique headboard. This adds a sense of elegance and depth to your bedroom decor.</li>
          <li><strong>Reading Nook:</strong> Place a jharokha near a window with a comfortable chair and a small side table, creating a cozy reading nook.</li>
        </ul>
  
        <h4>Entryway</h4>
        <ul>
          <li><strong>Welcoming Ambiance:</strong> Install a jharokha in your entryway to greet guests with a touch of traditional charm. Use it to showcase decorative items or greenery.</li>
        </ul>
  
        <h3>4. Complementary Decor</h3>
        <p>To enhance the aesthetic of your wooden jharokha, consider the following complementary decor elements:</p>
        <ul>
          <li><strong>Textiles:</strong> Use rich fabrics like silk or cotton in vibrant colors for cushion covers, curtains, or throws to contrast beautifully with the wood.</li>
          <li><strong>Lighting:</strong> Incorporate warm, soft lighting, such as wall sconces or lanterns, to create a cozy atmosphere around the jharokha.</li>
          <li><strong>Plants:</strong> Add indoor plants or flowers around the jharokha to bring in a touch of nature, which balances the intricate woodwork.</li>
        </ul>
  
        <h3>5. DIY Jharokha Art</h3>
        <p>For a personal touch, consider creating your own jharokha-inspired wall decor:</p>
        <ul>
          <li><strong>Shadow Box:</strong> Frame a small jharokha with decorative art inside. This can be a piece of fabric, artwork, or even a small plant.</li>
          <li><strong>Wall Hanging:</strong> Use smaller wooden pieces to create a decorative wall hanging that mimics the jharokha style.</li>
        </ul>
  
        <h3>6. Maintenance Tips</h3>
        <p>To ensure your wooden jharokha remains beautiful over time:</p>
        <ul>
          <li><strong>Dust Regularly:</strong> Use a soft cloth to dust the wood and prevent buildup.</li>
          <li><strong>Polish Occasionally:</strong> Apply a suitable wood polish to maintain the luster and protect against wear.</li>
          <li><strong>Avoid Excess Moisture:</strong> Keep it away from direct moisture to prevent warping or damage.</li>
        </ul>
  
        <h3>Conclusion</h3>
        <p>Incorporating wooden jharokha wall decor into your home is a wonderful way to celebrate traditional craftsmanship while adding a unique aesthetic touch. With the right placement, complementary decor, and personal flair, you can transform any space into a warm and inviting retreat that reflects your style. Embrace the beauty of this classic element and let it enhance the charm of your home.</p>
      `,
    },
];

const Blog = () => {
    return (
        <section className="container blog">
            <hgroup style={{ textAlign: 'center', marginBottom: '45px' }}>
                <h2>Inspiration & Insights: The Artist Strokes Blog</h2>
                <p className="container">
                    Stay updated with the latest trends, creative ideas, and expert tips in the world of art and decor. Our blog offers inspiration for your space, insights into the artistic process, and stories behind the masterpieces. Explore articles that spark creativity and help you transform your home with art.
                </p>
            </hgroup>
            <div className="row ">
                {blogData.map((blog, index) => (
                    <div className='blogCard' key={index}>
                        <div style={{ borderRadius: '25px', overflow: 'hidden' }}>
                            <img src={blog.img} alt={blog.title} style={{ width: '100%' }} />
                        </div>
                        <div className='card_content' >
                            <h6>{blog.title}</h6>
                            <p>{blog.desc}</p>
                            <Link to={`/blog/${encodeURIComponent(blog.title)}`} state={{ content: blog.content, img: blog.img }}> {/* Pass img URL */}
                                <button className="BuyNow">Read More</button>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Blog;
