import React, { useEffect, useRef, useState } from "react";
import { gsap } from 'gsap';
import './Header.scss';
import header from '../../../assets/jpg/banner.jpg';
import mobileBanner from '../../../assets/jpg/mobileBanner.jpg';

export default function Header() {
    const headerRef = useRef(null);
    const imageRef = useRef(null);
    const wrapperRef = useRef(null);
    const popupboxRef = useRef(null);
    const [bannerImage, setBannerImage] = useState(header);

    useEffect(() => {
        // Function to handle the image change based on screen size
        const handleResize = () => {
            if (window.innerWidth <= 500) {
                setBannerImage(mobileBanner);
            } else {
                setBannerImage(header);
            }
        };

        // Set initial image based on current window size
        handleResize();

        // Add event listener to update image on resize
        window.addEventListener('resize', handleResize);

        // GSAP animation
        if (typeof gsap !== 'undefined') {
            const tl = gsap.timeline();

            // Animate header elements
            tl.fromTo(headerRef.current,
                { opacity: 0, y: -50 },
                {
                    duration: 1,
                    opacity: 1,
                    y: 0,
                    ease: "power3.out"
                }
            );

            // Animate image placeholder
            tl.fromTo(imageRef.current,
                { opacity: 0, width: '0%' },
                {
                    duration: 1,
                    opacity: 1,
                    width: '98%',
                    ease: "power2.out"
                }
            );

            // Animate wrapper content
            tl.fromTo(wrapperRef.current.children,
                { opacity: 0, x: -20 },
                {
                    duration: 0.5,
                    opacity: 1,
                    x: 0,
                    stagger: 0.1,
                    ease: "power2.out"
                }
            );

            // Animate popupbox
            tl.fromTo(popupboxRef.current,
                { opacity: 0, scale: 0.8 },
                {
                    duration: 0.5,
                    opacity: 1,
                    scale: 1,
                    ease: "back.out(1.7)"
                }
            );
        } else {
            console.error("GSAP is not available. Make sure it's properly imported.");
        }

        // Cleanup: remove event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <header className="header" ref={headerRef} id="header">
            <div className="header__image-placeholder" ref={imageRef}>
                <img src={bannerImage} alt="banner" style={{ borderRadius: '30px' }} />
            </div>

            <div className="wrapper" ref={wrapperRef}>
                {/* Other content goes here */}
            </div>
        </header>
    );
}
