import './SubmitArtWork.scss';
import React, { useRef, useEffect } from 'react';
import { textFadeIn } from '../../animation/gsapAnimations';
import { ConnectWithUsCard } from '../UI/Cards';
import SubmitArtWorkForm from './SubmitArtWorkForm';
import { useLocation } from 'react-router-dom';

export default function SubmitYourArtWork() {
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const cardsRef = useRef(null);
    const formRef = useRef(null);

    const location = useLocation();

    useEffect(() => {

        if (location.hash === '#submit-artwork') {
            formRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    useEffect(() => {
        textFadeIn(titleRef.current);
        textFadeIn(descriptionRef.current, 0.2);
        textFadeIn(cardsRef.current, 0.4);
        textFadeIn(formRef.current, 0.6);
    }, []);

    return (
        <section id="submit-artwork" className='SubmitYourArtWorkcontainer'>
            <div className="container child">
                <div className='SubmitYourArtWorkwreper'>
                    <div className='submitcontent'>
                        <h2 ref={titleRef}>
                            Submit Your Artwork <span>&</span>
                            <br />
                            Join Our Community of Skilled Artisans
                        </h2>
                        <p ref={descriptionRef}>
                            😊 Talented artists, submit your artwork for consideration. Our team reviews submissions regularly. Join our network of Indian artisans and showcase your handcrafted creations to a global audience.
                        </p>
                    </div>
                    <div className='ConnectWithUsCardwreper counter' ref={cardsRef}>
                        <ConnectWithUsCard number={80} info='artists connected with us' bgColor='#FFEFF7' />
                        <ConnectWithUsCard number={150} info='Global Showcase' bgColor='#EFEFFF' />
                        <ConnectWithUsCard number={40} info='Artworks Displayed' bgColor='#EFF7FF' />
                    </div>
                </div>
                <div ref={formRef} className='form-container'>
                    <SubmitArtWorkForm />
                </div>
            </div>
        </section>
    );
}
