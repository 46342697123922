import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function ConfirmSubscription() {
    const { token } = useParams();
    const [message, setMessage] = useState('');

    useEffect(() => {
        const confirmSubscription = async () => {
            try {
                const response = await fetch(`https://www.artiststrokes.in/backend/api/newsletter/confirm/${token}`, {
                    method: 'GET',
                });

                const data = await response.json();

                if (response.ok) {
                    setMessage(data.message);
                } else {
                    setMessage(data.message || 'Confirmation failed. This link may be expired or invalid.');
                }
            } catch (error) {
                console.error('Error confirming subscription:', error);
                setMessage('An error occurred. Please try again later.');
            }
        };

        confirmSubscription();
    }, [token]);

    return (
        <div>
            <h2>Subscription Confirmation</h2>
            <p>{message}</p>
        </div>
    );
}

export default ConfirmSubscription;
