import React, { useState, useRef, useEffect, useMemo } from 'react';
import { FAQHeading } from '../UI/HeadingGroup';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import './FAQ.scss';

gsap.registerPlugin(ScrollTrigger);

export default function FAQ() {
    const faqs = useMemo(() => [
        { question: "What makes Artist Strokes unique?", answer: "At Artist Strokes, we combine creativity, craftsmanship, and personalization to deliver one-of-a-kind art pieces. Our collection is not only diverse but also curated from talented artists worldwide, offering you unique decor items like metal and wooden wall hangings, tabletop art, and custom paintings that suit any style." },
        { question: 'How do you ensure art quality?', answer: "We take pride in the high standards of craftsmanship that go into every piece. Our team carefully reviews each artwork to ensure it meets our quality criteria before shipping. From materials to finishing, every step is meticulously checked to guarantee your satisfaction." },
        { question: "Can I commission custom artwork?", answer: "Yes, we offer the option to commission custom artwork. Whether you have a specific design in mind or want something tailored to your space, our skilled artists can bring your vision to life. Just share your requirements, and we’ll work closely with you to create a personalized piece." },
        { question: "Why do you collect Aadhar numbers from buyers and artists?", answer: "We require Aadhar numbers for verification purposes only. For buyers, it helps us ensure that all purchases are made by genuine individuals. For artists, it verifies the authenticity of their submissions and helps prevent fraud. Rest assured, this data is used strictly for verification and will not be used for any other purpose without your permission." },
        { question: "Do you offer refunds or returns?", answer: "Yes, we provide refunds and returns, but eligibility is determined according to our terms of service. Please refer to our policy to understand the specific conditions under which refunds and returns are applicable." },
        { question: "How do you ship artworks?", answer: "We ship all artworks using One.Delivery, ensuring reliable and timely delivery to your doorstep. Each piece is carefully packaged to arrive safely and in perfect condition." }
    ], []);

    const [activeIndex, setActiveIndex] = useState(null);
    const answerRefs = useRef(faqs.map(() => React.createRef()));
    const faqItemRefs = useRef(faqs.map(() => React.createRef()));

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    useEffect(() => {
        faqs.forEach((_, index) => {
            const answerElement = answerRefs.current[index].current;
            if (index === activeIndex) {
                gsap.to(answerElement, {
                    height: 'auto',
                    opacity: 1,
                    duration: 0.3,
                    ease: 'power2.out'
                });
            } else {
                gsap.to(answerElement, {
                    height: 0,
                    opacity: 0,
                    duration: 0.3,
                    ease: 'power2.in'
                });
            }
        });
    }, [activeIndex, faqs]);

    useEffect(() => {
        faqItemRefs.current.forEach((ref, index) => {
            gsap.fromTo(ref.current,
                { 
                    x: index % 2 === 0 ? '-100%' : '100%',
                    opacity: 0
                },
                {
                    x: 0,
                    opacity: 1,
                    duration: 1,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: ref.current,
                        start: 'top 100%',
                        end: 'top 55%',
                        scrub: 1
                    }
                }
            );
        });

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    return (
        <>
         
            <section>
            <FAQHeading />
                <div className="faq-container container">
                    {faqs.map((faq, index) => (
                        <div key={index} className="faq-item" ref={faqItemRefs.current[index]}>
                            <div className="faq-question" onClick={() => toggleFAQ(index)}>
                                <h3>{faq.question}</h3>
                                <span>{activeIndex === index ? '-' : '+'}</span>
                            </div>
                            <div className="faq-answer" ref={answerRefs.current[index]} style={{ height: 0, opacity: 0 }}>
                                {faq.answer}
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    )
}
