import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Accordion from './Accordion';
import './Accordion.scss';
import { BrifInfoSlider } from '../slider/slider';
import printing from '../../assets/png/printing.png';
import printing2 from '../../assets/png/printing2.jpg';
import printing3 from '../../assets/png/printing3.jpg'

gsap.registerPlugin(ScrollTrigger);

export default function BrIefINformation() {
    const infoTextRef = useRef(null);
    const sliderSectionRef = useRef(null);

    const sliderImages = [
        printing,
        printing2,
        printing3,
        printing,
    ];

    const Accordiondata = [
        {
            title: 'What types of name plates do you offer?',
            content: "We provide a variety of name plates made from materials such as metal, wood, acrylic, and more. You can choose from custom designs and engraving options to create name plates that reflect your style or business identity.",
        },
        {
            title: 'How many years has your business been in operation?',
            content: "At Potdar Arts, we have been proudly serving customers for over 42 years. Our experience in the printing industry has allowed us to perfect our craft and provide high-quality signage and printing solutions to businesses and individuals alike."
        },
        {
            title: 'Which cities do you provide your services in?',
            content: 'We are currently offering our printing services in All cities in india, with plans to expand further. Our commitment to quality and customer satisfaction has helped us build a strong presence in these areas, and we are excited to grow and reach more clients in the near future.',
        },
        {
            title: 'How many team members do you have?',
            content: 'We are a dedicated team of 12 skilled professionals who are passionate about delivering exceptional printing services. Our team includes designers, fabricators, installers, and customer support staff who work together to bring your vision to life.',
        },
    ];
    useEffect(() => {
        gsap.fromTo(infoTextRef.current,
            { x: '-100%', opacity: 0 },
            {
                x: '0%',
                opacity: 1,
                duration: 1.5,
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: infoTextRef.current,
                    start: 'top 100%',
                    end: '40% 40%',
                    toggleActions: 'play none none reverse',
                    scrub: true
                }
            }
        );

        gsap.fromTo(sliderSectionRef.current,
            { x: '100%', opacity: 0 },
            {
                x: '0%',
                opacity: 1,
                duration: 1.5,
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: sliderSectionRef.current,
                    start: 'top 100%',
                    end: '40% 40%',
                    
                    toggleActions: 'play none none reverse',
                    scrub: true
                }
            }
        );
    }, []);

    return (
        <section>
            <div className="info-container container">
                <div className="info-text" ref={infoTextRef}>
                    <h2>Brief information about our print business</h2>
                    <Accordion data={Accordiondata} />
                </div>

                <div className="slider-section" ref={sliderSectionRef}>
                    <BrifInfoSlider sliderImages={sliderImages} />
                </div>
            </div>
        </section>
    );
};
