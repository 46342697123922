import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import './Testimonials.scss';
import { textFadeIn } from '../../animation/gsapAnimations';
import testimonials from '../../data/reviews.json';


export default function Testimonials() {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const headerRef = useRef(null);
    const swiperRef = useRef(null);

    const descriptionRef = useRef(null);

    useEffect(() => {
        textFadeIn(headerRef.current);
        textFadeIn(swiperRef.current, 0.3);

        textFadeIn(descriptionRef.current, 0.2);
    }, []);

    return (
        <section id='testimonials'>
            <div className="testimonials-section container">

                <div className="testimonials-header" ref={headerRef}>
                    {/* <h6>Testimonials</h6> */}
                    <h2>What Our Customers Are Saying</h2>
                    <p ref={descriptionRef}>Hear directly from our satisfied customers! Our testimonials highlight the experiences of individuals who have brought beauty and inspiration into their homes through our unique art pieces. From stunning wall decor to custom-made artworks, see why people love Artist Strokes for their art and decor needs.</p>

                    <div className="swiper-navigation">
                        <button ref={prevRef} className="swiper-button prev-button">&larr;</button>
                        <button ref={nextRef} className="swiper-button next-button">&rarr;</button>
                    </div>
                </div>

                <div ref={swiperRef} className='testimonial-swiper '>
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={1}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 10
                            },
                            600: {
                                slidesPerView: 2,
                                spaceBetween: 15
                            },

                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            1440: {
                                slidesPerView: 3,
                                spaceBetween: 20
                            }
                        }}
                        loop={true}
                        navigation={{
                            prevEl: prevRef.current,
                            nextEl: nextRef.current,
                        }}
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = prevRef.current;
                            swiper.params.navigation.nextEl = nextRef.current;
                        }}
                        modules={[Navigation]}
                        className="testimonial-wrapper"
                        observer={true}
                        observeParents={true}
                        resizeObserver={true}
                    >
                        {testimonials.map((testimonial, index) => (
                            <SwiperSlide key={index} >
                                <div className="testimonial-card">
                                    <div className="user-image">
                                        {/* <img
                                            src={testimonial.image}
                                            alt={testimonial.name}
                                            className="testimonial-image"
                                        /> */}
                                    </div>
                                    <h3>{testimonial.name}</h3>
                                    <small>{testimonial.location}</small>
                                    <blockquote>{testimonial.review}</blockquote>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};
