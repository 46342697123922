// BlogDetail.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './blog.scss';
import useScrollToTop from '../scrolltotop/useScrollToTop';




const BlogDetail = () => {
  const location = useLocation();
  const { content, img } = location.state || { content: '', img: '' };

  useScrollToTop();

  return (
    <div className="blog-detail-container">
      {img && <img src={img} alt="Blog Cover" className="blog-detail-image" />}

      <div className="blog-detail-content" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default BlogDetail;
