import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NavBar from "../../components/shared/Navbar/NavBar";
import Footer from "../../components/shared/Footer/Footer";
import './productPages.scss';
import ProductBuyingForm from '../../components/forms/productBuying';
import ImageZoom from '../../components/productZoom/ImageZoom';

export default function ProductDetails() {
    const { productId } = useParams(); // Use productId from the URL
    const [artwork, setArtwork] = useState(null);
    const [selectedSize, setSelectedSize] = useState(""); // State to track selected size

    // Fetch artwork data
    useEffect(() => {
        const fetchArtwork = async () => {
            try {
                const response = await fetch(`https://www.artiststrokes.in/backend/api/products/${productId}`);
                const text = await response.text();
                const data = JSON.parse(text);
                setArtwork(data);
                if (data.prices_as_per_size?.length > 0) {
                    setSelectedSize(data.prices_as_per_size[0].size || ""); // Set default size
                }
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };

        fetchArtwork();
    }, [productId]);

    // Check if artwork is undefined
    if (!artwork) {
        return <div>No product details available for product ID "{productId}".</div>;
    }

    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value); // Update selected size
    };

    const { title, desc, img, prices_as_per_size } = artwork;

    return (
        <>
            <NavBar />
            <section className="container mob_padding">
                <div className="productdetails row">
                    <div className="ImgPreview col-6">
                        <div className='wrapper'>
                            <ImageZoom src={img} alt={title} />
                        </div>
                    </div>
                    <div className="containt">
                        <div>
                            <h3 style={{ marginBottom: '10px' }}>{title}</h3>
                            <p>{desc}</p>
                        </div>
                        <div className="dropdown-wrapper">
                            <label htmlFor="sizeDropdown">Available Sizes:</label>
                            <select
                                id="sizeDropdown"
                                className="form-select"
                                value={selectedSize}
                                onChange={handleSizeChange}
                            >
                                {prices_as_per_size.map((option, index) => (
                                    <option key={index} value={option.size}>
                                        {option.size || 'Standard'} - {option.price}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <hr />
                        <i>To purchase this product, please fill out the form below with your details.</i>
                        <ProductBuyingForm 
                            productId={artwork.productId} 
                            selectedSize={selectedSize} 
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
