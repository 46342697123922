// src/components/ImageZoom.js
import React, { useState } from 'react';
import './imageZoom.scss'; // Ensure you have the same styles

const ImageZoom = ({ src, alt }) => {
    const [isZoomed, setIsZoomed] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseEnter = () => {
        setIsZoomed(true);
    };

    const handleMouseLeave = () => {
        setIsZoomed(false);
    };

    const handleMouseMove = (e) => {
        const { left, top } = e.currentTarget.getBoundingClientRect();
        const x = ((e.clientX - left) / e.currentTarget.offsetWidth) * 100;
        const y = ((e.clientY - top) / e.currentTarget.offsetHeight) * 100;
        setPosition({ x, y });
    };

    return (
        <div
            className="image-zoom-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
        >
            <img
                src={src}
                alt={alt}
                style={{
                    width: '100%',
                    transition: 'transform 0.2s ease',
                    transform: isZoomed ? `scale(1.5)` : 'scale(1)',
                    transformOrigin: `${position.x}% ${position.y}%`, // Zoom in where the cursor is
                }}
            />
        </div>
    );
};

export default ImageZoom;
