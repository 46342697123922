import './slider.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';    
import 'swiper/css/pagination'; 
import { Pagination, Autoplay } from 'swiper/modules';

export function BrifInfoSlider({sliderImages}) {
    return (
        <Swiper
            modules={[Pagination, Autoplay]} 
            autoplay={{ delay: 3000, disableOnInteraction: false }} 
            pagination={{ clickable: true }}
            spaceBetween={30}
            slidesPerView={1}
        >
            {sliderImages.map((img, idx) => (
                <SwiperSlide key={idx}>
                    <img src={img} alt='slide-img' className="slider-image" />
                </SwiperSlide>
            ))}
        </Swiper>
    );
}
