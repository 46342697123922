import React from 'react';
import { OurServiceCard } from "../UI/Cards";
import { Heading } from "../UI/HeadingGroup";
import './OurService.scss';

import art from '../../assets/png/art.png';
import printing from '../../assets/png/printing.png';
import meditation from '../../assets/png/Meditation.png';

const cardsData = [
  {
    image: art,
    title: "Art Selling Services",
    description: "Find and purchase stunning, handcrafted artworks from talented artists around the world. From paintings to decorative pieces, our collection is curated to help you bring beauty and inspiration into your home.",
  },
  {
    image: printing,
    title: "Printing Services",
    description: "We provide top-tier printing solutions, including name plates, neon signs, metal engravings, and more. Whether you need personalized signage for your business or high-quality custom prints, our printing services are here to bring your ideas to life.",
  },
  {
    image: meditation,
    title: 'Meditation Training',
    description: "Enhance your mental well-being with our meditation training sessions. Led by experienced instructors, we offer personalized guidance to help you find peace, balance, and mindfulness in your daily life. Perfect for beginners and seasoned practitioners alike.",
  },
];

export default function OurService() {
  return (
    <section id='ourServices'>
      <div className="container">
        <Heading
          heading='Our Services'
          title='Discover Our Unique Services'
          des="At Artist Strokes, we offer a diverse range of services designed to bring creativity, quality, and well-being into your life. Whether you're looking to buy one-of-a-kind artwork, need professional printing services, or seek inner peace through meditation, we’ve got you covered."
        />

        <div className="cardcontainer">
          {cardsData.map((card, index) => (
            <OurServiceCard
              key={index}
              image={card.image}
              title={card.title}
              description={card.description}
            />
          ))}
        </div>
      </div>
    </section>
  )
}