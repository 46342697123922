import React, { useEffect, useRef } from 'react';
import './Card.scss'; 
import { AnimatedCounter, textFadeIn } from '../../animation/gsapAnimations';

export function HowWeWorkCard({icon, title, description, bgColor}) {
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    textFadeIn(titleRef.current);
    textFadeIn(descriptionRef.current, 0.2);
  }, []);

  return (
    <div className="howWeworkcard">
      <div className="card-icon" style={{ backgroundColor: bgColor }}>
        <img src={icon} alt='icon'/>
      </div>
      <h6 className="card-title" ref={titleRef}>{title}</h6>
      <p className="card-description" ref={descriptionRef}>{description}</p>
    </div>
  );
}

export function OurServiceCard({ image, title, description }) {
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    textFadeIn(titleRef.current);
    textFadeIn(descriptionRef.current, 0.2);
  }, []);

  return (
    <div className="OurServicecard">
      <img src={image} alt={title} />
      <h4 ref={titleRef}>{title}</h4>
      <p className="card-description" ref={descriptionRef}>{description}</p>
    </div>
  );
}

export function ConnectWithUsCard({number, info, bgColor}) {
  const infoRef = useRef(null);

  useEffect(() => {
    textFadeIn(infoRef.current);
  }, []);

  return (
    <div className="connectWithUsCard" style={{ backgroundColor: bgColor }}>
      <AnimatedCounter value={number} />
      <h6 ref={infoRef}>{info}</h6>
    </div>
  );
}
