import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ContactNow, Logo, Navlinkbtn } from '../../UI/Button';
import LoveHelp from '../../LovetoHelp/inqueryForm';
import './Navbar.scss';

export default function NavBar() {
    const navRef = useRef(null);
    const wrapperRef = useRef(null);
    const contactRef = useRef(null);
    const menuBarRef = useRef(null);
    const logoRef = useRef(null);
    const menuWrapRef = useRef(null);
    const menuNavRef = useRef(null);
    const navigationRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [showLoveHelp, setShowLoveHelp] = useState(false);

    const handleContactNowClick = () => {
        setShowLoveHelp(true);
    };

    const handleCloseLoveHelp = () => {
        setShowLoveHelp(false);
    };

    useEffect(() => {
        // Ensure GSAP is available
        if (typeof gsap !== 'undefined') {
            const navElements = [contactRef.current, menuBarRef.current];
            const wrapperChildren = Array.from(wrapperRef.current.children).filter(child => child !== logoRef.current);

            // Create a timeline for sequential animations
            const tl = gsap.timeline();

            // Animation for logo (now first)
            tl.fromTo(logoRef.current,
                { opacity: 0, scale: 0.9, y: '-250', },
                {
                    y: 0,
                    duration: .6,
                    opacity: 1,
                    scale: 1,
                    ease: "power2.out",
                }
            );

            // Animation for wrapper children, excluding the logo
            tl.fromTo(wrapperChildren,
                { opacity: 0, x: -20 },
                {
                    duration: 0.5,
                    opacity: 1,
                    x: 0,
                    stagger: 0.1,
                    ease: "power2.out",
                }
            );

            // Animation for nav elements (now last)
            tl.fromTo(navElements,
                { y: -50, opacity: 0 },
                {
                    duration: 0.1,
                    y: 0,
                    opacity: 1,
                    stagger: 0.2,
                    ease: "power1.out",
                }
            );

            // Fade in animation for navigation
            tl.fromTo(navigationRef.current.children,
                { opacity: 0, y: 20 },
                {
                    duration: 0.4,
                    opacity: 1,
                    y: 0,
                    stagger: 0.1,
                    ease: "power2.out",
                }
            );

            // Set initial state of menu-wrap to be hidden
            gsap.set(menuWrapRef.current, { x: '100%', display: 'none' });
            gsap.set(menuNavRef.current.children, { y: 50, opacity: 0 });
        } else {
            console.error("GSAP is not available. Make sure it's properly imported.");
        }
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        if (!isMenuOpen) {
            gsap.set(menuWrapRef.current, { display: 'block' });
            gsap.to(menuWrapRef.current, {
                x: '0%',
                duration: 0.5,
                ease: 'power2.out',
                onComplete: () => {
                    gsap.to(menuNavRef.current.children, {
                        y: 0,
                        opacity: 1,
                        duration: 0.5,
                        stagger: 0.1,
                        ease: 'power2.out'
                    });
                    // Animation for contactNow in menu-wrap
                    gsap.to(menuWrapRef.current.querySelector('.menuContact'), {
                        y: 0,
                        opacity: 1,
                        duration: 0.5,
                        ease: 'power2.out'
                    });
                }
            });
        } else {
            gsap.to(menuNavRef.current.children, {
                y: 50,
                opacity: 0,
                duration: 0.3,
                stagger: 0.05,
                ease: 'power2.in',
                onComplete: () => {
                    gsap.to(menuWrapRef.current, {
                        x: '100%',
                        duration: 0.5,
                        ease: 'power2.in',
                        onComplete: () => gsap.set(menuWrapRef.current, { display: 'none' })
                    });
                }
            });
            // Animation for contactNow in menu-wrap
            gsap.to(menuWrapRef.current.querySelector('.menuContact'), {
                y: 50,
                opacity: 0,
                duration: 0.3,
                ease: 'power2.in'
            });
        }
    };

    return (
        <>
            <div className="help">
                {showLoveHelp && <LoveHelp onClose={handleCloseLoveHelp} />}
            </div>

            <nav ref={navRef} id='nav'>
                <div className='wrepare' ref={wrapperRef}>
                    <a href="/" className='logoLInk'>
                        <div ref={logoRef} style={{ zIndex: 10 }}><Logo /></div>
                    </a>
                  
                </div>
                <ul className='navigation' ref={navigationRef}>
                        <Navlinkbtn name='Home' href="/" />
                        <Navlinkbtn name='Services' href="#ourServices" />
                        <Navlinkbtn name='Products' href="#products" />
                        <Navlinkbtn name='About us' href="#aboutFounder" />
                        <Navlinkbtn name='Testimonials' href="#testimonials" />
                    </ul>
                <div ref={contactRef}>
                    <ContactNow onClick={handleContactNowClick} />
                </div>

                <button className='menu-bar' ref={menuBarRef} onClick={toggleMenu}>☰</button>

            </nav>
            {/* mobile */}
            <div className='menu-wrap' ref={menuWrapRef} style={{ display: 'none' }}>
                <div className="mark">
                    <button className='menu-bar' onClick={toggleMenu}>✖</button>
                </div>
                <ul className='navigation' ref={menuNavRef}>
                    <Navlinkbtn name='Home' href="/" />
                    <Navlinkbtn name='Services' href="#ourServices" />
                    <Navlinkbtn name='Products' href="#products" />
                    <Navlinkbtn name='About us' href="#aboutFounder" />
                    <Navlinkbtn name='Testimonials' href="#testimonials" />
                </ul>
                <div className="menuContact">
                     <ContactNow onClick={handleContactNowClick} />
                </div>
                    
                
            </div>
        </>
    )
}

