import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './Heading.scss';
import Hrline from '../../assets/png/hrline.png'
import { headingAnimation, hrlineAnimation } from '../../animation/gsapAnimations';

gsap.registerPlugin(ScrollTrigger);

export function HowWeWorkHeading() {
    const headingRef = useRef(null);
    const hrlineRef = useRef(null);

    useEffect(() => {
        headingAnimation(headingRef.current);
        hrlineAnimation(hrlineRef.current);
    }, []);

    return (
        <div className='howwework container' style={{ rowGap: '15px' }}>
            <h2 ref={headingRef}>Why Choose Artist Strokes?</h2>
            <p style={{ textAlign: 'center' }}>From personalized service to one-of-a-kind, handcrafted decor, we stand out by offering more than just art. We provide an experience tailored to your tastes and values.
            </p>
            <div ref={hrlineRef} className='hrLine'> <img src={Hrline} alt='HeadingDeco' /></div>
        </div>
    )
}

export function Heading({ heading, title, des }) {
    const containerRef = useRef(null);
    const headingRef = useRef(null);
    const titleRef = useRef(null);
    const desRef = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: containerRef.current,
                start: "top 80%",
                end: "bottom 20%",
                toggleActions: "play none none reverse"
            }
        });

        // Animation for heading
        tl.fromTo(headingRef.current,
            { y: 20, opacity: 0 },
            { duration: 0.5, y: 0, opacity: 1 }
        );

        // Animation for title
        tl.fromTo(titleRef.current,
            { y: 20, opacity: 0 },
            { duration: 0.5, y: 0, opacity: 1 },
            "-=0.3"
        );

        // Animation for description
        tl.fromTo(desRef.current,
            { y: 20, opacity: 0 },
            { duration: 0.5, y: 0, opacity: 1 },
            "-=0.3"
        );
    }, []);

    return (
        <div className='headingWreper' ref={containerRef}>
            <div className='ourserviccontainer'>
                <img src={Hrline} alt='HeadingDeco' />
                <h5 ref={headingRef}>{heading}</h5>
                <img src={Hrline} alt='HeadingDeco' />
            </div>

            <h2 ref={titleRef}>{title}</h2>

            <p ref={desRef}>{des}</p>
        </div>
    )
}

export function FAQHeading() {
    const faqRef = useRef(null);

    useEffect(() => {
        gsap.fromTo(faqRef.current.children,
            { y: 30, opacity: 0 },
            {
                duration: 0.8,
                y: 0,
                opacity: 1,
                stagger: 0.2,
                ease: "power2.out",
                scrollTrigger: {
                    trigger: faqRef.current,
                    start: "top 80%",
                    end: "bottom 20%",
                    toggleActions: "play none none reverse"
                }
            }
        );
    }, []);

    return (
        <>
            <div className='FAQHeading' ref={faqRef}>
                <div><p>Get to know us</p></div>
                <h2>Frequently Asked Questions</h2>
            </div>
        </>
    )
}

export function BestsellingpaintingcategoriesHeading({ heading, title, des }) {
    return (
        <div className='BestsellingpaintingcategoriesHeading'>


            <h5>{heading}</h5>


            <h2 >{title}</h2>

            <p>{des}</p>


        </div>
    )
}

export function ArtisanCraftedDecorHeading() {


    return (
        <div className='ArtisanCraftedDecorHeading'>
            <h2>Artisan Crafted Decor- Lorem Ipsum</h2>
            <p>Lorem ipsum dolor sit amet consectetur. Maecenas nam sit eleifend et in in dignissim. Scelerisque et orci ipsum ultrices. Porttitor in lectus eget non. Venenatis varius id lacus morbi tempor non ridiculus quis morbi.</p>
        </div>
    )
}



