import React from 'react';
import './HowWeWork.scss';
import { HowWeWorkCard } from '../UI/Cards'; 
import { HowWeWorkHeading } from '../UI/HeadingGroup';
import icon1 from '../../assets/png/best-seller.png';
import icon2 from '../../assets/png/business.png';
import box from '../../assets/png/eco-friendly.png';

const cardsData = [
  {
    icon: icon1,
    title: 'Personalized Artworks Tailored to You',
    description: "We offer customized artworks to fit your unique taste and style. Whether you're looking for a personalized painting, metal wall art, or a specific wooden decor piece, our artists work closely with you to bring your vision to life.",
    bgColor: '#fff',
  },
  {
    icon: icon2,
    title: 'Handcrafted Excellence',
    description: "Each piece in our collection is handcrafted by skilled artisans, ensuring high-quality craftsmanship and attention to detail. Our products are not mass-produced; they are made with care, giving every item a one-of-a-kind touch.",
    bgColor: '#fff',
  },
  {
    icon: box,
    title: "Eco-Friendly and Sustainable Materials",
    description: "At Artist Strokes, we are committed to using sustainable and eco-friendly materials. Our wooden and metal decor items are crafted from responsibly sourced materials, helping you beautify your space while caring for the environment.",
    bgColor: '#fff',
  },
];

export default function HowWeWork() {
  return (
    <section id='' >
      <HowWeWorkHeading /> 

      <div className="how-we-work-cards-container container">
        {cardsData.map((card, index) => (
          <HowWeWorkCard
            key={index}
            icon={card.icon}
            title={card.title}
            description={card.description}
            bgColor={card.bgColor}
          />
        ))}
      </div>
    </section>
  );
}
