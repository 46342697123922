import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import data from '../../data/products.json';
import { Heading } from '../UI/HeadingGroup';
import './BestSellingCategories.scss';
import homeDecoreIcon from '../../assets/png/shelf.png';
import paintingIcon from '../../assets/png/canvas.png';
import RawArtPieceIcon from '../../assets/png/sculpture.png';
import VintageIcon from '../../assets/png/vase.png';
import woodenAcceIcon from '../../assets/png/sculptures.png';
import zarokaIcon from '../../assets/png/frame.png';

export default function BestSellingCategories() {
    const [activeTab, setActiveTab] = useState('home_decores');
    const [tabData, setTabData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (data[activeTab]) {
            const firstArtworks = data[activeTab].subcategories.map(subcategory => ({
                category: subcategory.category,
                artworks: subcategory.artworks // Keep all artworks
            })).filter(item => item.artworks.length > 0);
            setTabData(firstArtworks);
        } else {
            setTabData([]);
        }
    }, [activeTab]);

    const handleCardClick = (subcategory) => {
        navigate(`/more-products/${encodeURIComponent(subcategory.category)}#nav`, {
            state: {
                artworks: subcategory.artworks, // Pass all artworks of the clicked subcategory
                category: subcategory.category // Pass the category name
            }
        });
    };
    
    const tabs = [
        { id: 'home_decores', label: "Home Decores", img: homeDecoreIcon },
        { id: 'painting', label: "Painting", img: paintingIcon },
        { id: 'raw_art', label: "Art craft material", img: RawArtPieceIcon },
        { id: 'vintage_gallery', label: "Vintage gallery", img: VintageIcon },
        { id: 'wooden-wallets', label: "Wooden wallets", img: woodenAcceIcon },
        { id: 'Jharokha', label: "Jharokha", img: zarokaIcon }
    ];

    return (    
        <section id='products'>
          <div className="container">
              <Heading
                heading='Best Selling Categories'
                title='Uncover the Finest Art and Decor for Every Space'
                des='Explore our diverse range of best-selling categories that cater to all tastes and styles. From Home Decor and captivating Paintings to unique Raw Art Pieces and timeless Vintage Metal Art, we offer something for every art lover. Our collection also includes handcrafted Wooden Accessories and the artistic charm of Zaroka, ensuring you find the perfect piece to enhance your space.'
            />
          </div>

       
        <div className='CategorieName container'>
                <ul>
                    {tabs.map((tab, index) => (
                        <li key={index} onClick={() => setActiveTab(tab.id)} className={`tab-button ${activeTab === tab.id ? "active" : ""}`}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <img src={tab.img} style={{ width: '50px', height: '50px', margin: 'auto' }} alt={tab.label} />
                            </div>
                            {tab.label}
                        </li>
                    ))}
                </ul>
            </div>
        

            <div className='Categoriecontentwreper'>
                <div className='Categoriecontantent container'>
                    {tabData.length > 0 ? (
                        tabData.map((subcategory) => (
                            <div key={subcategory.category} className="card" onClick={() => handleCardClick(subcategory)}>
                                <div style={{ backgroundColor: 'white' }}>
                                    <h3 className='category'>{subcategory.category}</h3>
                                    <div className='art_works_card'>
                                    <img src={subcategory.artworks[0].img} alt={subcategory.artworks[0].title} />
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <>
                            <p></p>
                            <p style={{ textAlign: 'center', margin: 'auto', color: 'red' }}>
                                Our team is dedicated to bringing you innovative and creative works that inspire and delight. Products will get updated sooner...
                            </p>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
}
