// useScrollToTop.js
import { useEffect } from 'react';

const useScrollToTop = () => {
    useEffect(() => {
        window.scrollTo(0, 0); 
        window.scrollTo({ top: 0, behavior: 'smooth' });// Scroll to the top of the page when the component mounts
    }, []);
};

export default useScrollToTop;
