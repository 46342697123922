import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Nav from '../shared/Navbar/NavBar';
import Footer from '../shared/Footer/Footer';
import { Heading } from '../UI/HeadingGroup';
import { useLocation } from 'react-router-dom';
import './BestSellingCategories.scss';
import '../../sass/common.scss';
import '../../pages/productGallery/productPages.scss';
import SubmitYourArtWork from "../../components/SubmitArtWork/SubmitArtWork";

export default function MoreProducts() {
    const { category } = useParams();
    const location = useLocation();
    const { artworks } = location.state || { artworks: [] };
    const navigate = useNavigate();

    const handleBuyNow = (artwork) => {
        // const productSlug = artwork.title.toLowerCase().replace(/ /g, '-');
        navigate(`/product-details/${artwork.productId}`, { state: { artwork } });
    };

    const getLowestPrice = (prices) => {
        if (prices.length === 0) return "N/A"; // Handle cases where there are no prices
        return Math.min(...prices.map(price => parseFloat(price.price.replace(/[^0-9.-]+/g,"")))).toFixed(2) + '/-'; // Extract and format the lowest price
    };

    useEffect(() => {
        const scrollToNav = () => {
            const navElement = document.getElementById('nav');
            if (navElement) {
                navElement.scrollIntoView({ behavior: 'smooth' });
            }
        };

        scrollToNav();
        window.addEventListener('hashchange', scrollToNav);

        return () => {
            window.removeEventListener('hashchange', scrollToNav);
        };
    }, []);

    let heading = `Artist Strokes Presenting - ${category}`;

    return (
        <>
            <Nav />
            <section id='products'>
           <div className="container">
           <Heading
                    heading={heading}
                    title='Uncover the Finest Art and Decor for Every Space'
                    des='Explore our diverse range of best-selling categories that cater to all tastes and styles. From Home Decor and captivating Paintings to unique Raw Art Pieces and timeless Vintage Metal Art, we offer something for every art lover. Our collection also includes handcrafted Wooden Accessories and the artistic charm of Zaroka, ensuring you find the perfect piece to enhance your space.'
                />
           </div>
                <div className='Categoriecontentwreper'>
                    <div className='Categoriecontantent container' style={{ gap: '60px 10px' }}>
                        {artworks.length > 0 ? (
                            artworks.map((artwork, index) => (
                                <div key={index} className="card">
                                    <div className="cardContaint" style={{ backgroundColor: 'white' }}>
                                        <div className='art_works_card'>
                                            <img src={artwork.img} alt={artwork.title} />
                                        </div>
                                        <div>
                                            <h6>{artwork.title}</h6>
                                            <p>{artwork.desc}</p>
                                        </div>

                                        {/* Bind the lowest price from prices_as_per_size */}
                                        <p style={{ fontSize: '20px' }}>
                                            Starting Price: <b>{getLowestPrice(artwork.prices_as_per_size)}</b>
                                        </p>
                                        <button className='BuyNow' onClick={() => handleBuyNow(artwork)}>Buy Now</button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <>
                                
                                <p className='para_center' style={{ textAlign: 'center', margin: 'auto', color: 'red',  }}>
                                    Our team is dedicated to bringing you innovative and creative works that inspire and delight. Products will get updated sooner...
                                </p>
                            </>
                        )}
                    </div>
                </div>
            </section>
            <SubmitYourArtWork />
            <Footer />
        </>
    );
}
