import React from 'react';
// import logo from '../../assets/svg/dummy_logo.svg';
import logo from '../../assets/png/1x/logo.png';


import facebook from '../../assets/png/facebook.png';
import instagram from '../../assets/png/instagram.png';

import './Button.scss';

export function Logo() {
    return (
        <div style={{width:'150px'}}>
            <img src={logo} alt='logo' />
        </div>
    );
}

export function Navlinkbtn({ name, href }) {
    const scrollToSection = (e) => {
        e.preventDefault();
        const section = document.querySelector(href);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <li>
            <a href={href} className='Navlinkbtn' onClick={scrollToSection}>{name}</a>
        </li>
    );
}

export function ContactNow({ onClick }) {
    return (
        <div className='mainwreper'>
            <p className='letsconnect'>
                let&apos;s connect below
            </p>
            <div className='contactwreper'>
                <div className='icon'>
                    <a href="https://www.instagram.com/artissstrokes_india/" target='blank'>
                        <img src={instagram} alt='instagram' />
                    </a>
                </div>
                <div className='icon'>
                    <a href="https://www.facebook.com/profile.php?id=100064276054350&mibextid=ZbWKwL" target='blank'><img src={facebook} alt='facebook' /></a>
                </div>

                <button className='contactnow' onClick={onClick}>Contact Now</button>
            </div>
        </div>
    );
}